import { AppBar, Box, Drawer, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import logo from "../../Image/LMDlogo.png";
import App from "../../Image/App.png";
import Play from "../../Image/Play.png";
import MenuIcon from "@mui/icons-material/Menu";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const navigator = useNavigate();
  const [state, setState] = useState(false);

  const toggleDrawer = () => {
    setState((p) => !p);
  };
  return (
    <AppBar
      sx={{
        backgroundColor: "transparent",
        boxShadow: "0",
      }}
    >
      <Box
        sx={{
          background: {
            xs: "rgba(216, 216, 216, 0.9)",
            md: "rgba(216, 216, 216, 0.5)",
          },
          py: 1,
        }}
      >
        <Box sx={{}}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: { xs: "space-between", sm: "space-between" },
              px: { xs: 3, sm: 10, lg: 22, xl: 25 },
            }}
          >
            <Box
              onClick={() => navigator("/")}
              sx={{ width: { xs: "100px", md: "110px" }, cursor: "pointer" }}
            >
              <img src={logo} alt="" width="100%" />
            </Box>
            <Box
              sx={{
                display: { xs: "none", md: "flex" },
                alignItems: "center",
              }}
            >
              <Box onClick={() => navigator("/")}>
                <TypographyViwe value="Why Us" id="#WhyUs" />
              </Box>
              <Box
              onClick={() => navigator("/")}
                sx={{
                  px: 3,
                }}
              >
                <TypographyViwe value="Features" id="#Features" />
              </Box>
              <Box onClick={() => navigator("/")}>
                <TypographyViwe value="Contact" id="#ContactUs" />
              </Box>
              <Box sx={{ width: "135px", px: 3 }}>
              <ImageViwe media={Play}  id="https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app" />
              </Box>
              <Box>
              <ImageViwe media={App} id="https://apps.apple.com/us/app/lunch-maybe-dinner/id1585014668"/>
              </Box>
            </Box>
            <Box
              sx={{
                display: { xs: "flex", md: "none" },
              }}
            >
              <Box>
                <MenuIcon fontSize="large" onClick={toggleDrawer} />
              </Box>
              <Drawer
                anchor={"top"}
                open={state}
                variant="persistent"
                onClose={() => toggleDrawer(true)}
                PaperProps={{
                  sx: {
                    background: "rgba(216, 216, 216, 1)",
                    backdropFilter: "blur(3px)",
                    width: "100%",
                    pb: 3,
                    display: "flex",
                    alignItems: "center",
                    zIndex: "1",
                    mt: 10,
                  },
                }}
              >
                <Box onClick={() => toggleDrawer()} sx={{ mt: 2 }}>
                  <Box>
                    <Box>
                      <DrawerView value="Why Us" id="#WhyUs" />
                    </Box>
                    <Box>
                      <DrawerView value="Features" id="#Features" />
                    </Box>
                    <Box>
                      <DrawerView value="Contact" id="#ContactUs" />
                    </Box>
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mt: 3,
                      }}
                    >
                      <Box
                        sx={{
                          width: { xs: "135px", sm: "200px" },
                          pr: { xs: 1, sm: 3 },
                        }}
                      >
                        <ImageViwe media={Play}  id="https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app" />
                      </Box>
                      <Box
                        sx={{
                          width: { xs: "130px", sm: "200px" },
                          pl: { xs: 1, sm: 3 },
                        }}
                      >
                        <ImageViwe media={App} id="https://apps.apple.com/us/app/lunch-maybe-dinner/id1585014668"/>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Drawer>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            background: {
              xs: "rgba(216, 216, 216, 0.9)",
              md: "rgba(216, 216, 216, 0.7)",
            },
            backdropFilter: "blur(3px)",
            position: "absolute",
            top: 0,
            width: "100%",
            height: "100%",
            zIndex: "-1",
          }}
        ></Box>
      </Box>
    </AppBar>
  );
};

export default Navbar;

function TypographyViwe({ value, id }) {
  return (
    <Box>
      <Box>
        <Link
          href={id}
          target={"_blank"}
          sx={{
            textDecoration: "none",
          }}
        >
          <Typography variant="body1" color="initial" fontWeight="550">
            {value}
          </Typography>
        </Link>
      </Box>
    </Box>
  );
}

function ImageViwe({ media,id }) {
  return (
    <Box>
      <Box sx={{cursor:"pointer"}}>
      <Link href={id} target="_blank">
        <img src={media} alt="" width="100%" />
        </Link>
      </Box>
    </Box>
  );
}

function DrawerView({ value, id }) {
  return (
    <Box
      sx={{
        mt: 3,
      }}
    >
      <Link
        href={id}
        sx={{
          textDecoration: "none",
        }}
      >
        <Box
          sx={{
            background: "rgba(255, 255, 255, 0.5)",
            width: { xs: "300px", sm: "600px" },
            borderRadius: "7px",
            py: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{}}>
            <Box>
              <Typography
                variant="body4"
                color="initial"
                textAlign="center"
                fontWeight="400"
              >
                {value}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Link>
    </Box>
  );
}
