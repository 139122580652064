import { Box } from "@mui/system";
import React, { useEffect } from "react";
import HomeScreen from "../Screen/HomeScreen";
import Whylmd from "../Screen/Whylmd";
import Discover from "../Screen/Discover";
import AboutUs from "../Screen/AboutUs";
import ContactUs from "../Screen/ContactUs";

const Home = () => {
    useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
    <Box>
     
      <HomeScreen />
      <Whylmd />
      <Discover />
      <AboutUs />
      <ContactUs />
      
    </Box>
  );
};

export default Home;
