import { Box, Typography } from "@mui/material";
import React from "react";
import TypographyViwe from "./TypographyViwe";
import Line3 from "../../Image/Line3.png";
import Image from "../../Image/Image.png";
import background from "../../Image/Background.png";
import background1 from "../../Image/Background1.png";
import About from "../../Image/About1.png";
import About1 from "../../Image/About2.png";


const AboutUs = () => {
  return (
    <Box
    id="Features"
      sx={{
        position: "relative",
      }}
    >
      <Box>
        <TypographyViwe
          width="420px"
          value="What Our Users Say About Us"
          media={Line3}
          title="Hear From Real Users of Lunch Maybe Dinner"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: { xs: "space-around", sm: "space-between" },
          px: { xs: 0, sm: 10, lg: 22, xl: 25 },
          flexDirection: { xs: "column", lg: "row" },
          mt: 10,
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          
          }}
        >
          <Box>
            <Box sx={{ pl: 0 }}>
              <AboutViwe
                media={background1}
                px="1rem"
                value="I've tried other dating apps before, but none of them really understood my love for food. Lunch Maybe Dinner was different - I found someone who loves sushi just as much as I do!"
                subtitle="Sarah, 32"
                width="85%"
                height="210px"
                height2="230px"
                image={About}
              />
            </Box>
            <Box sx={{ ml: { xs: 2, sm: 5, md: 30, lg: 10, xl: 30 }, mt: 3 }}>
              <Box >
                <AboutViwe
                  media={background}
                  pl="2.8rem"
                  value="I love that I can swipe and match with people who share my passion for trying new restaurants. It's so much fun to discover new places together!"
                  subtitle="Michael, 32"
                  width="93%"
                  height="210px"
                  height2="230px"
                  image={About1}
                  
                />
              </Box>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: { xs: "center", md: "start" },
            }}
          >
            <Typography
              variant="body1"
              color="initial"
              sx={{
                width: { xs: "95%", sm: "90%",lg:"95%", xl: "95%" },
                mt: { xs: 5, md: 10, lg: 20 },
                lineHeight: { xs: 1.5, md: 2 },
                pl:{lg:1,xl:7}
              }}
            >
              Don't just take our word for it - see what our satisfied users
              have to say about Lunch Maybe Dinner. Check out our testimonials
              section to read real-life success stories and discover how our app
              has helped people like you find their perfect match.
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box

        sx={{
          display: { xs: "none", lg: "list-item" },
          mt: 20,
          background: "-webkit-gradient(linear, right top, left bottom, color-stop(10%,rgba(255, 255, 255, 0.5)), color-stop(50%,rgba(255, 170, 201, 0.5)))", 
          WebkitFilter: "blur(200px)",
          position: "absolute",
          top: "0%",
          width: " 100%",
          height: "80%",
          zIndex: "-1",
         
        }}
      ></Box>
      <Box
      
        sx={{
          display: { xs: "list-item", lg: "none" },
          mt: 20,
          // backgroundImage:
          //   "linear-gradient(0deg, rgba(255, 255, 255, 0.5) 20%, rgba(255,170, 201, 0.5) 70%)",
            background: "-webkit-gradient(linear, left top, left top, color-stop(50%,rgba(255, 255, 255, 0.5)), color-stop(50%,rgba(255, 170, 201, 0.5)))", 

            WebkitFilter: "blur(150.5px)",
          position: "absolute",
          top: "0%",
          width: " 100%",
          height: "65%",
          zIndex: "-1",
          borderRadius: "13px",
        }}
      ></Box>
    </Box>
  );
};

export default AboutUs;

function AboutViwe({ media, pl, value, subtitle, px, width, height, height2,image ,mt}) {
  return (
    <Box
      sx={{
        position: "relative",
      }}
    >
      <Box sx={{}}>
        <Box
          sx={{
            width: { xs: "98%", sm: "460px",lg:"440px",xl:"460px" },
            height: { xs: height, sm: height2 },
          }}
        >
          <img src={media} alt="" width="100%" height="100%" />
        </Box>
        <Box>
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              px: px,
              pl: pl,
            }}
          >
            <Box sx={{ pt: 2.5 }}>
              <img src={Image} alt="" />
            </Box>
            <Box>
              <Box>
                <Typography
                  variant="body2"
                  color="initial"
                  sx={{ width: { xs: "90%", sm: width, md: "25rem",lg:"24rem",xl:"24rem" }, py: 1.5 }}
                >
                  {value}
                </Typography>
              </Box>
              <Box sx={{
                display:"flex",
                alignItems:"center",
                mt:mt
              }}>
              <Box>
                <img src={image} alt="" width="40px"/>
              </Box>
              <Box>

              
              <Typography variant="body1" color="initial" sx={{ pl: 1 }}>
                {subtitle}
              </Typography>
              </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
