import React from 'react'
import Typography from '@mui/material/Typography'
import { Box } from '@mui/material'

const Header = ({value,subtitle}) => {
  return (
    <Box sx={{mt:"8rem",mb:"2rem",px: { xs: 3, sm: 10, lg:22,xl: 25 },}}>
        <Box>
            <Typography variant="h3" color="initial" fontWeight="600">
                {value}
            </Typography>
        </Box>
        
    </Box>
  )
}

export default Header