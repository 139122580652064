import { Box } from "@mui/system";
import React from "react";
import TypographyViwe from "./TypographyViwe";
import Line2 from "../../Image/Line2.png";

import Typography from "@mui/material/Typography";
import { Grid } from "@mui/material";
import { DiscoverData } from "../../../utils/Constent/HomeConstent";

const Discover = () => {
  return (
    <Box
      id="WhyUs"
      sx={{
        position: "relative",
      }}
    >
      <Box>
        <TypographyViwe
          width="650px"
          value="Discover the Benefits of Lunch Maybe Dinner"
          media={Line2}
          title="Discover the benefits of Lunch Maybe Dinner - the ultimate dating or networking app for those who love good company and great conversations. With advanced matching, unique dating ideas, and a user-friendly interface, finding your next match has never been easier."
        />
      </Box>

      <Box
        sx={{
          mt: { xs: 5, md: 6 },
          mx: { xs: 3, sm: 10, lg: 22, xl: 25 },
        }}
      >
        <Box>
          <Grid
            container
            spacing={{
              xs: 3,
              sm: 2,
              xl: 5,
            }}
            sx={{}}
          >
            {DiscoverData.map((n) => {
              return (
                <GridViwe key={n}>
                  <DiscoverViwe
                    media={n.media}
                    value={n.value}
                    subtitle={n.subtitle}
                    width={n.width}
                  />
                </GridViwe>
              );
            })}
          </Grid>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "list-item" },
            mt: 20,
           
              background: "-webkit-gradient(linear, left top, right bottom, color-stop(50%,rgba(129, 212, 249, 0.5)), color-stop(50%,rgba(255, 170, 201, 0.5)))", /* Chrome,Safari4+ */
              WebkitFilter:"blur(180.5px)",
          
            position: "absolute",
            top: "0%",
            width: " 100%",
            height: "70%",
            zIndex: "-1",
            borderRadius: "13px",
          }}
        ></Box>
        <Box
       
          sx={{
            display: { xs: "list-item", md: "none" },
            mt: 20,
            // backgroundImage:
            //   "-webkit-linear-gradient(100deg, rgba(255, 170, 201, 0.5) 50%, rgba(129, 212, 249, 0.5) 10%)",
            background: "-webkit-gradient(linear, left top, left bottom, color-stop(50%,rgba(129, 212, 249, 0.5)), color-stop(50%,rgba(255, 170, 201, 0.5)))", 

            WebkitFilter: "blur(100.5px)",
            position: "absolute",
            top: "0%",
            width: " 100%",
            height: "90%",
            zIndex: "-1",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default Discover;

function DiscoverViwe({ media, value, subtitle, width }) {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width:"100%",
          background: " rgba(255, 255, 255, 0.7)",
          backdropFilter: "blur(10px)",
          py: 6,
          height: "130px",
          borderRadius: "36px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            mb: 2,
          }}
        >
          <img src={media} alt="" width={width} />
        </Box>
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="body1" color="initial">
            {value}
          </Typography>
          <Typography variant="body1" color="initial">
            {subtitle}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}

function GridViwe({ children }) {
  return (
    <Grid item xs={12} sm={6} lg={3}>
      {children}
    </Grid>
  );
}
