import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Line1 from "../../Image/Line1.png";
import Back from "../../Image/Back.png";
import Back1 from "../../Image/Back2.png";
import TypographyViwe from "./TypographyViwe";
import { WhylmdData } from "../../../utils/Constent/HomeConstent";

const Whylmd = () => {
  return (
    <Box>
      <Box>
        <TypographyViwe
          width="400px"
          value="Why Lunch Maybe Dinner"
          media={Line1}
          title="Lunch Maybe Dinner is a great dual app for finding the perfect dating match or connecting with networking enthusiasts."
          mb="-1rem"
        />
      </Box>

      <Box
        sx={{
          position: "relative",
          mt: { xs: 2, md: 6 },
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            height: "180px",
            
          }}
        >
          <img src={Back} alt="" width="100%" />
        </Box>
        <Box
          sx={{
            display: { xs: "flex", md: "none" },
            height: "580px",
            
          }}
        >
          <img src={Back1} alt="" width="100%" height="100%" style={{marginTop:"20px"}}/>
        </Box>
        <Box
          sx={{
            display: "flex",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "10%",
              mx: { xs:3, sm: 10,lg: 22,xl:25 },
               borderRadius: "21px",
            }}
          >
            <Grid container spacing={{ xs: 3,sm:2, xl: 5 }}>
              {WhylmdData.map((n) => {
                return (
                  <GridView key={n.value}>
                    <CardView value={n.value} subtitle={n.subtitle} />
                  </GridView>
                );
              })}
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Whylmd;

function CardView({ value, subtitle }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderRadius: "21px",
      }}
    >
      <Box
        sx={{
          width: { xs: "100%", sm: "100%", md: "100%" },
          borderRadius: "21px",
        }}
      >
        <Box
          boxShadow={0}
          sx={{
            border: "1px solid #D5D5D5",
            background:
              "linear-gradient(180deg, rgba(255, 255, 255, 0.09) 0%, rgba(183, 183, 183, 0.09) 100%)",
            backdropFilter: "blur(25px)",
            px: { xs: 1, sm: 1, md: 3, lg: 4 },
            // py: { xs: 4, sm: 5, md: 3, lg: 4 },
            height: "170px",
            borderRadius: "21px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ textAlign: "center" }}>
            <Typography variant="h2" color="initial" fontWeight="700">
              {value}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                variant="body3"
                color="initial"
                sx={{
                  // px:{xs:0,lg:1},
                  pt: 1,
                }}
              >
                {subtitle}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function GridView({ children }) {
  return (
    <Grid item xs={12} md={4}>
      {children}
    </Grid>
  );
}
