import React from "react";
import Typography from "@mui/material/Typography";
import { Box, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigator = useNavigate(""); 
  return (
    <Box
      sx={{
        background: " #F7F7F7",
        py: 1.5,
        marginTop: { xs: "6rem", md: "6rem" },
      }}
    >
      <Box sx={{
        display: "flex",
        flexDirection:{xs:"column",md:"row"},
        justifyContent:"space-between",
        px: { xs: 0, md: 10, lg: 25 },
       
        alignItems:"center"

      }}>
        <Box>
          <Typography
            variant="body2"
            color="initial"
            sx={{
              opacity: "0.5",
            }}
          >
            © 2023 Lunch Maybe Dinner | All Rights Reserved
          </Typography>
        </Box>
        <Box sx={{
        display: "flex",
        cursor:"pointer"
      }}>
      
          <Typography
            variant="body2"
            color="initial"
            sx={{
              opacity: "0.5",
            }}
          >
          <Link onClick={()=> navigator ("/Privacypolicy") } sx={{
            color:"#000",
            pr:0.9
             
          }}>Privacy policy</Link>
             |
          </Typography>
          <Typography
          
            variant="body2"
            color="initial"
            sx={{
              opacity: "0.5",
              px:0.9
            }}
          >
          <Link onClick={()=> navigator ("/TermsandConditions")} sx={{
            color:"#000",
            pr:0.9
             
          }}> Terms & Conditions</Link>
            |
          </Typography>
          <Typography
            variant="body2"
            color="initial"
            sx={{
              opacity: "0.5",
            }}
          >
           <Link onClick={()=> navigator ("/CodeofConduct")} sx={{
            color:"#000",
            pr:0.9
             
          }}> Code of Conduct</Link>
            
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default Footer;
