import { Box } from '@mui/system'
import React, { useEffect } from 'react'
import Header from '../PolicyScreen/Header'
import TypographtView from "../PolicyScreen/TypographyView"

const Conduct = () => {
    useEffect(() => window.document.scrollingElement?.scrollTo(0, 0), [])
  return (
    <Box>
    <Box>
      <Header value="Code of Conduct" subtitle="" />
    </Box>
    <Box sx={{ mb: "0rem" }}>
      <TypographtView subtitle="Curabitur tempor, ex sed eleifend maximus, orci libero blandit urna, ut faucibus diam mi quis nisi. Aenean et quam vestibulum mauris suscipit feugiat et ac massa. Nullam quis faucibus ex. Proin erat ante, accumsan accumsan ornare ac, convallis eget ante. Vestibulum vestibulum ante eget pellentesque accumsan. Morbi in velit rhoncus, sagittis ipsum sed, tincidunt nibh. Praesent quis arcu leo. Nunc ante massa, congue ut leo eget, efficitur finibus sapien." />
      <TypographtView subtitle="Curabitur posuere tellus at nunc consectetur faucibus. Etiam porta viverra mauris quis posuere. Sed id velit et arcu ultricies dapibus eu sed ex. Sed sodales enim elit, vitae tincidunt nisi placerat sit amet. Praesent finibus, erat a rhoncus eleifend, quam lacus rutrum justo, eu euismod est velit at massa. Nam efficitur sapien ut dolor tincidunt, in viverra ex auctor. Fusce elementum nisi elit, vitae pharetra risus rhoncus non. Vivamus dictum rhoncus ex, at fermentum libero rutrum et. Ut viverra, lorem quis gravida dictum, risus urna finibus nibh, non interdum nisl mauris sed neque. Praesent sodales nibh sed quam congue, nec venenatis felis tincidunt. Suspendisse a dolor quis urna imperdiet fringilla. Suspendisse potenti." />
      <TypographtView subtitle="Maecenas dictum tellus elementum tincidunt ornare. Nullam efficitur sodales nulla, pharetra fermentum felis ullamcorper semper. Donec rutrum eleifend pulvinar. Sed pellentesque quam at nibh dictum dictum. Phasellus pellentesque, nisl non pellentesque iaculis, nisl sem pretium risus, eu euismod nunc dui quis arcu. Nunc eget accumsan turpis. Praesent cursus mollis tortor id scelerisque. Proin ornare convallis dolor a condimentum. Curabitur vestibulum, est sit amet venenatis placerat, turpis eros vulputate dui, sit amet fringilla tellus arcu eu ligula. Nunc gravida congue turpis, a semper urna euismod in. Nullam consectetur tellus sed ex placerat posuere." />
      <TypographtView subtitle="Cras magna quam, commodo eget tellus quis, porttitor molestie tortor. Integer euismod ultrices nunc, ac egestas ipsum consectetur sed. Pellentesque commodo viverra dolor, in aliquam orci ullamcorper at. Aenean at quam sagittis, tempor arcu eu, pretium nisi. Integer blandit feugiat lacus gravida blandit. Nulla sit amet nisi eget ex pellentesque convallis nec sed nunc. Proin pulvinar eu arcu vitae fringilla. Etiam tincidunt dignissim sodales. Duis ullamcorper tortor sem, et molestie nisl aliquet nec. Nam eleifend non lorem quis condimentum. Nunc venenatis nisl dui, ultricies venenatis arcu venenatis eget. Pellentesque viverra, erat quis bibendum mattis, mauris magna varius ipsum, ac consequat metus nulla ac mauris. Cras eget tellus odio. Proin luctus vestibulum metus non ultricies. Fusce porttitor nibh sit amet consequat maximus." />
      <TypographtView subtitle="Pellentesque bibendum libero sed est condimentum, sit amet ultricies lorem aliquet. Mauris efficitur vel eros in convallis. Quisque luctus dolor vitae elementum euismod. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. In et nulla in tortor auctor blandit. Quisque finibus tempor feugiat. Ut a fermentum est, vitae tempus sem. Nunc eget euismod turpis, in ullamcorper elit. Vivamus mollis nunc ac pellentesque elementum. Donec tellus nulla, aliquet nec nibh ut, ullamcorper pretium augue. Donec congue egestas enim, sed pharetra felis tempor id. Sed viverra nulla et arcu gravida, tincidunt rhoncus felis viverra. Duis dapibus ac metus nec sollicitudin." />
      <TypographtView subtitle=" In sed volutpat risus. Nullam dignissim quam non tellus luctus rutrum eget et quam. Sed at ante vel diam vulputate mollis. Nam tempor sollicitudin ligula ut gravida. Quisque est ipsum, congue vitae imperdiet non, egestas in ipsum. Sed ac mauris rutrum tellus congue pretium sed sed nibh. Curabitur laoreet porttitor enim in porta. Aenean sed ante ut dui molestie facilisis. In dapibus gravida mi id efficitur. Nullam quis urna at sapien sodales venenatis quis venenatis dolor. Aliquam erat volutpat. Proin in purus consequat, vestibulum tortor non, pellentesque arcu. Sed at viverra risus. Ut tincidunt, massa at commodo ultricies, libero sapien luctus urna, accumsan viverra justo diam id sem." />
      <TypographtView subtitle="Pellentesque ultricies ex ut pellentesque scelerisque. Morbi ut purus nunc. Etiam molestie blandit dolor, ut tempus dui aliquet et. Nulla malesuada sapien quis aliquet iaculis. Quisque et cursus purus, volutpat consequat mi. Mauris at orci lobortis, eleifend urna id, finibus magna. Sed suscipit tortor quis ante tempus commodo. Duis id tortor eu nisi finibus accumsan. Nulla condimentum varius ex vel iaculis. Etiam iaculis rutrum orci quis sollicitudin. Sed vel diam convallis tortor fermentum aliquet vitae eu arcu. Maecenas dictum iaculis nulla eu dignissim." />

      <TypographtView subtitle="Sed semper placerat ex, eget tristique est euismod at. Curabitur sed elit quam. Quisque non tempor sem. Donec at efficitur urna. Donec vulputate volutpat elit in placerat. Curabitur mi mauris, pulvinar quis auctor eu, lobortis vehicula ipsum. Vestibulum a efficitur diam, eu sodales lacus. Proin sit amet sollicitudin ex, ac faucibus nibh." />

      <TypographtView subtitle="Morbi nec erat non lacus tristique faucibus non ut justo. Donec id mollis elit, non pulvinar nibh. Aenean nunc erat, vestibulum mollis dictum id, eleifend vitae ex. Curabitur finibus sollicitudin elementum. Quisque efficitur lacus euismod mauris dignissim, ut rhoncus leo placerat. Etiam ut dolor ut tellus pretium faucibus. Mauris eleifend egestas luctus. Nunc ultricies sit amet orci eu tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla facilisi. Integer pharetra erat orci, a porta lacus luctus eu. Pellentesque semper placerat maximus." />

      <TypographtView subtitle="Pellentesque lobortis ante sed pretium eleifend. Sed augue turpis, fermentum eu bibendum quis, iaculis consectetur diam. Fusce sodales eu nibh eget porta. Maecenas efficitur diam sapien, pulvinar ultrices sem dapibus sit amet. Nam non vestibulum nulla. Proin tellus lectus, pellentesque quis est ut, eleifend ultrices nibh. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Praesent eget faucibus nulla." />

      <TypographtView subtitle="Nullam finibus dignissim nulla et porta. Nunc fermentum vel nulla non fringilla. Phasellus tincidunt orci vel bibendum fringilla. Integer aliquet suscipit felis a hendrerit. Sed et leo non est fermentum ultrices. Sed a leo vel erat suscipit molestie. Cras hendrerit faucibus lacinia. Ut molestie, libero sit amet luctus venenatis, sem purus lacinia justo, a mollis felis mauris pulvinar ipsum. Sed rutrum convallis elit, sit amet iaculis felis pulvinar eu." />

      <TypographtView subtitle="Sed semper placerat ex, eget tristique est euismod at. Curabitur sed elit quam. Quisque non tempor sem. Donec at efficitur urna. Donec vulputate volutpat elit in placerat. Curabitur mi mauris, pulvinar quis auctor eu, lobortis vehicula ipsum. Vestibulum a efficitur diam, eu sodales lacus. Proin sit amet sollicitudin ex, ac faucibus nibh." />

      <TypographtView subtitle="Suspendisse eu blandit ante. In justo mi, pretium vel rutrum id, suscipit vitae purus. In vehicula, diam vitae congue consectetur, ligula nisi sagittis libero, vel consectetur neque leo ut orci. Sed consequat, tortor non accumsan imperdiet, libero dolor accumsan nunc, a ultricies augue sapien sed leo. Nulla eu vestibulum quam. Maecenas porta lorem neque, eget convallis mauris posuere luctus. Cras lobortis libero quis elit aliquam, at molestie augue luctus. Pellentesque scelerisque ornare elit, ac congue enim rhoncus ac. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Quisque feugiat, urna quis ultrices efficitur, risus arcu viverra felis, vitae placerat purus turpis commodo arcu. Integer ac odio vitae libero tempor tristique quis et sem." />

      <TypographtView subtitle="Nunc scelerisque, diam non faucibus luctus, ipsum nunc placerat nibh, et porttitor nisl erat ut metus. Curabitur egestas, erat vitae aliquet bibendum, nisi neque congue orci, nec ultrices turpis arcu volutpat mauris. Cras arcu turpis, tempor vitae varius mattis, tincidunt ut nulla. Praesent tempus ullamcorper diam. Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Ut condimentum nisi at tincidunt luctus. Donec porta malesuada dolor sit amet dictum. Sed nisi odio, egestas vel tortor eget, eleifend tristique ipsum. Aliquam sit amet ipsum eget felis ultrices euismod." />

      <TypographtView subtitle="Aenean consequat pulvinar congue. Phasellus vel suscipit lectus, vitae efficitur diam. Nulla at dui sodales, lobortis ligula mattis, pellentesque justo. Phasellus non consequat tortor, quis rhoncus magna. Pellentesque placerat eleifend facilisis. Nullam ornare elementum libero, eu ultricies turpis. Phasellus cursus aliquam quam sed tincidunt. Nam quis ipsum in ex lacinia elementum vitae sed magna. Vivamus enim justo, euismod sed posuere ut, porttitor vel elit. Phasellus consequat euismod erat non ultricies. Donec imperdiet faucibus rutrum. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer imperdiet ipsum leo, vel convallis ligula cursus eu." />

      <TypographtView subtitle="Duis facilisis finibus tellus, id ultrices urna efficitur sed. Duis mauris nisi, laoreet nec leo eu, posuere hendrerit nulla. Praesent ullamcorper a neque quis feugiat. Sed in volutpat orci. Pellentesque ac suscipit dolor. Nulla iaculis porta est a lobortis. Donec gravida metus eu ipsum aliquam, nec porta ex egestas. Morbi faucibus sit amet erat eu rhoncus. Nam dignissim arcu neque, ultrices pulvinar felis sagittis ut. Pellentesque in felis et velit fringilla mollis non et nisi." />

      <TypographtView subtitle="Etiam eleifend sit amet mauris nec fermentum. Cras vulputate finibus diam, sed sagittis augue tincidunt elementum. Donec et mollis leo. Mauris nulla velit, faucibus a ultrices non, pulvinar vitae quam. Aenean a sapien tincidunt, luctus mauris ac, finibus ex. Sed malesuada placerat tellus ut rutrum. Phasellus iaculis, metus nec convallis venenatis, eros velit commodo tortor, nec varius turpis ipsum quis justo. Suspendisse rhoncus erat quis euismod accumsan. Quisque non lobortis magna. Sed sed magna sem. Aenean at odio sem. Nullam lacinia tristique dolor, sed facilisis nisl dignissim vel. Nulla interdum porttitor condimentum. Nulla in libero a metus bibendum luctus eget nec sapien. Aliquam sodales non tellus a bibendum." />

      <TypographtView subtitle="Suspendisse tincidunt nulla a felis auctor iaculis. Pellentesque blandit eros vitae leo aliquet, sed laoreet orci aliquet. Vestibulum non pulvinar leo. Curabitur pulvinar est non nisl aliquet, sit amet sollicitudin magna maximus. Sed quis risus vel sapien porta imperdiet. Morbi pretium mi non urna tincidunt consequat. Vivamus dignissim odio et justo mattis viverra. Quisque a consequat nibh, et auctor diam. Maecenas non velit metus. Nulla nec faucibus justo." />

      <TypographtView subtitle="Pellentesque habitant morbi tristique senectus et netus et malesuada fames ac turpis egestas. Aliquam viverra mauris nec enim dapibus ultricies non sed mi. Vestibulum id molestie neque. Duis porttitor, nulla sed pellentesque pellentesque, ex magna sollicitudin dui, quis vehicula risus purus et sapien. Maecenas eget accumsan nulla. Sed cursus ornare lorem nec pharetra. Integer eu fringilla erat. Vestibulum condimentum in nisl ut sagittis. Nunc efficitur, tortor vitae consectetur blandit, tellus justo maximus magna, ut hendrerit urna leo vitae leo. Cras non interdum tellus. Maecenas lobortis neque enim, eu convallis libero auctor et. Nulla eu euismod mi, sit amet commodo urna. Mauris rutrum tincidunt augue, interdum accumsan augue molestie vitae. Ut consequat nisi tellus, eu congue justo hendrerit a. Integer vitae est venenatis, placerat purus a, aliquet est." />

      <TypographtView subtitle="In vitae pretium nisi. Morbi lobortis finibus dolor, vel mollis lacus pulvinar eu. Phasellus scelerisque quis erat eget finibus. Fusce nunc urna, egestas sit amet consectetur ut, commodo at enim. Curabitur et dignissim lorem, et vulputate ante. Suspendisse lectus mauris, fermentum sit amet lobortis id, molestie a nisi. Mauris elit leo, lacinia nec dui sed, volutpat sagittis augue. Phasellus accumsan blandit fermentum. Vestibulum massa quam, maximus gravida sem sit amet, accumsan cursus mi. Sed in nibh diam. Morbi neque diam, dictum ut lacus a, volutpat lacinia ipsum. Cras nisi est, imperdiet nec accumsan eget, varius a elit." />

      <TypographtView subtitle="Etiam dignissim nulla nec tellus iaculis auctor. Suspendisse vestibulum, diam in gravida scelerisque, est lorem gravida nulla, mollis mattis est diam in massa. Proin congue at mauris nec porta. Sed ut tristique tortor. Fusce et augue consectetur, venenatis est mollis, efficitur mi. Ut vehicula, urna non porttitor fringilla, leo sapien vulputate nulla, sit amet porta nulla erat in augue. Nunc eros nulla, accumsan vitae scelerisque non, volutpat nec felis. Proin vulputate augue eu sapien vehicula sollicitudin. Sed interdum libero ut tempor interdum. Etiam neque erat, malesuada nec lectus sed, congue iaculis magna." />

      <TypographtView subtitle="Generated 20 paragraphs, 1827 words, 12369 bytes of Lorem Ipsum" />
    </Box>
  </Box>
  )
}

export default Conduct