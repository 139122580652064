import Easy from "../../Component/Image/Easy.png";
import Unique from "../../Component/Image/Unique.png";
import Chat from "../../Component/Image/Chat.png";
import main from "../../Component/Image/Lmd1.png";
import Search from "../../Component/Image/Search.png";
import main1 from "../../Component/Image/Lmd2.png";
import main2 from "../../Component/Image/Lmd3.png";
import main3 from "../../Component/Image/Lmd4.png";
import main4 from "../../Component/Image/Lmd5.png";
import main5 from "../../Component/Image/Lmd6.png";

//Home screen SwiperData

export const SwiperData = [
  {
    media: main,
  },
  {
    media: main2,
  },
  {
    media: main1,
  },
];

// Home screen SwiperresponsiveData

export const SwiperresponsiveData=[
    {
        media: main3,
      },
      {
        media: main4,
      },
      {
        media: main5,
      },
]



//Why Lunch Maybe Dinner Data
export const WhylmdData = [
  {
    value: "User-Friendly Interface",
    subtitle:
      "Lunch Maybe Dinner's ultra-friendly interface makes finding a match effortless and intuitive.",
  },
  {
    value: "Safe and Secure",
    subtitle:
      "Lunch Maybe Dinner prioritizes your safety with industry-standard security measures to protect your personal information.",
  },
  {
    value: "Personalized Profile",
    subtitle:
      "Lunch Maybe Dinner's profile showcases your interests and preferences to find the perfect match.",
  },
];

//Discover the Benefits of Lunch Maybe Dinner Data

export const DiscoverData = [
  {
    media: Easy,
    value: "Easy Navigation",
    subtitle: "and Browsing",
    width:"38px"
  },
  {
    media: Unique,
    value: "Unique and Memorable",
    subtitle: "Date Ideas",
    width:"50px"
  },
  {
    media: Search,
    value: "Search for Matches",
    subtitle: "Based on Criteria",
    width:"50px"
  },
  {
    media: Chat,
    value: "Real-Time Chat",
    subtitle: "and Messaging",
    width:"50px"
  },
];
