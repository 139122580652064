import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { Autoplay, Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/effect-creative";
import { EffectCreative } from "swiper";
import { SwiperData, SwiperresponsiveData } from "../../../utils/Constent/HomeConstent";
import { isAndroid, isIOS, isWindows, isMacOs } from 'react-device-detect';


const HomeScreen = () => {

  return (
    <Box sx={{}}>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Box>
          <Swiper
         
            loop={true}
            effect={"creative"}
            spaceBetween={0}
            centeredSlides={true}
            creativeEffect={{
          prev: {
            shadow: true,
            translate: ["-100%", 0, 0],
           
          },
          next: {
            translate: ["100%", 0, 0],
          },
        }}
            // autoplay={true}
            speed={800}
            keyboard={true}
            autoplay={{
              delay: 3000,
              disableOnInteraction: false,
            }}
            // pagination={{
            //   clickable: true,
            // }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation,EffectCreative]}
            className="mySwiper"
          >
            {SwiperData.map((e) => {
              return (
                <SwiperSlide key={e.value}>
                  <SwiperImageView media={e.media} />
                </SwiperSlide>
              );
            })}
          </Swiper>

          <Swiper
         
         loop={true}
         effect={"creative"}
         spaceBetween={0}
         centeredSlides={true}
         creativeEffect={{
       prev: {
         shadow: true,
         translate: ["-20%", 0, -1],
       },
       next: {
         translate: ["100%", 0, 0],
       },
     }}
         // autoplay={true}
         speed={700}
         keyboard={true}
         autoplay={{
           delay: 2500,
           disableOnInteraction: false,
         }}
         // pagination={{
         //   clickable: true,
         // }}
         navigation={true}
         modules={[Autoplay, Pagination, Navigation,EffectCreative]}
         className="mySwiper"
       >
         {SwiperresponsiveData.map((e) => {
           return (
             <SwiperSlide key={e.value}>
               <SwiperResponsiveView media={e.media} />
             </SwiperSlide>
           );
         })}
       </Swiper>

          
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              top: { xs: "20%", md: "30%", lg: "38%" },
            }}
          >
            <Box sx={{ mt: { xs: 20, sm: 10, md: 0 } }}>
              <BoxViwe  />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HomeScreen;

function BoxViwe() {

  // const redirectToAppStore = () => {
  //   if (isAndroid) {
  //     // Redirect Android users to the Google Play Store
  //     window.location.href = 'https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app&pli=1';
  //   } else if (isIOS) {
  //     // Redirect iPhone users to the App Store
  //     window.location.href = 'https://apps.apple.com/us/app/lunch-maybe-dinner/id1585014668';
  //   } else {
  //     // Handle other devices here or provide a fallback action
  //     console.log('Unsupported device.');
  //   }
  // };

  const redirectToAppStore = () => {
    if (isAndroid) {
      // Redirect Android users to the Google Play Store
      window.location.href = 'https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app&pli=1';
    } else if (isIOS) {
      // Redirect iPhone users to the App Store
      window.location.href = 'https://apps.apple.com/us/app/lunch-maybe-dinner/id1585014668';
    } else if (isWindows) {
      // Redirect Windows users to a specific URL (replace with your Windows app URL)
      window.location.href = 'https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app&pli=1';
    } else if (isMacOs) {
      // Redirect macOS users to a specific URL (replace with your macOS app URL)
      window.location.href = 'https://apps.apple.com/us/app/lunch-maybe-dinner/id1585014668';
    } else {
      // Handle other devices here or provide a fallback action
      console.log('Unsupported device.');
    }
  };
  
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: { xs: "90%", sm: "100%", md: "100%" },
          background: "rgba(0, 0, 0, 0.3)",
          backdropFilter: "blur(10px)",
          py:{xs:3,md:3,lg:"50px"},
          px:{xs:1,sm:5,md:"45px",lg:"60px"},

          borderRadius: "38px",
        }}
      >
        <Box
          sx={{
            textAlign: "center",
          }}
        >
          <Typography variant="h1" color="#fff">
            Your Next Great Love Story or
            

          </Typography>
          <Typography variant="h1" color="#ffff" sx={{ pt: 0.3 }}>
          Network Connection Starts Here
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Typography
              variant="body2"
              color="#fff"
              sx={{
                width: { xs: "92%", sm: "30rem", md: "30rem", lg: "35rem" },
                pt:{xs:2,md:3},
              }}
            >
              At Lunch Maybe Dinner, your next great match is just a click away. Our platform makes it easy to connect with like-minded individuals and find the perfect match. Join now and start your journey!
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              marginTop: "1rem",
            }}
          >
            <Button
            // href="https://play.google.com/store/apps/details?id=com.lunchmaybedinner.app&pli=1"
            target="_blank"
            onClick={redirectToAppStore}
            // onClick={() => navigator (url=(redirectToAppStore))}
              sx={{
                color: "#fff",
                backgroundColor: "#D5004E",

                borderRadius: "50px",
                textTransform: "none",
                fontWeight: "500",
                fontSize: { xs: "12px", sm: "15px" },
                px: { xs: 4, sm: 4.5 },
                py: { xs: 1, sm: 1 },
                "&:hover": {
                  backgroundColor: "#D5004E",
                  color: "#fff",
                },
              }}
            >
              Start Today, it’s Free
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

function SwiperImageView({ media }) {
  return (
    <Box>
      <Box
        sx={{
          display: { xs: "none", md: "flex" },
          width: "100%",
          height: "100vh",
          objectFit: "cover",
          objectPosition: "center",
        }}
      >

        <img
          src={media}
          alt=""
          width="100%"
          height="100%"
          style={{ margin: "0", padding: "0" }}
        />
      </Box>
    </Box>
  );
}

function SwiperResponsiveView({ media }) {
  return (
    <Box>
      <Box
            sx={{
              display: { xs: "flex", md: "none" },
              width: "100%",
              height: "100vh",
              objectFit: "cover",
              objectPosition: "center",
            }}
          >
            <img
              src={media}
              alt=""
              width="100%"
              height="100%"
              style={{ margin: "0", padding: "0" }}
            />
          </Box>
    </Box>
  );
}
