import { Box } from "@mui/material";
import React from "react";

const TypographyView = ({ subtitle }) => {
  return (
    <Box sx={{ mb: 1, px: { xs: 3, sm: 10, lg: 22, xl: 25 } }}>
      {subtitle}
    </Box>
  );
};

export default TypographyView;
