import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import TypographyViwe from "./TypographyViwe";
import Line4 from "../../Image/Line4.png";
import Phone from "../../Image/Phone.png";
import Msg from "../../Image/Msg.png";
import Vector from "../../Image/Vector.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = () => {
  const DataValue = {
    name: "",
    last: "",
    email: "",
    number: "",
    message: "",
  };
  const [data, setData] = useState(DataValue);
  const handelonChange = (e) => {
    setData((p) => ({ ...p, [e.target.name]: e.target.value }));
  };

  const handelOnClick = () => {
    if (!data.name) {
      toast("Please fill the Name");
    } else if (!data.last) {
      toast("Please fill the Last Name");
    } else if (!data.email) {
      toast("Please fill the Email");
    } else if (!data.number) {
      toast("Plase fill the Phone Number");
    } else if (!data.message) {
      toast("Plase fill the MessageBox");
    }
  };
  return (
    <Box id="ContactUs">
      <Box>
        <TypographyViwe
          width="420px"
          value="Get in Touch with Lunch Maybe Dinner"
          media={Line4}
          title="We're Here to Help You Find Your Perfect Match"
        />
      </Box>
      <Box
        sx={{
          mt: { xs: 3, sm: 6 },
          display: "flex",
          justifyContent: { xs: "none", md: "center" },
          flexDirection: { xs: "column", md: "row" },
          alignItems: { xs: "none", md: "center" },
          px: { xs: 3, sm: 10, md: 0 },
        }}
      >
        <Box sx={{ mr: { xs: 0, md: 2.5 } }}>
          <Box>
            <Box
            
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: { xs: "start", sm: "start" },
                paddingTop: { xs: "1rem", sm: "0" },
                justifyContent: "center",
                width: "100%",
              }}
            >
             <ToastContainer/>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  width: {xs:"100%",sm:"102.5%",md:"100%"}
                }}
              >
                <TextFieldView
                  placeholder="john"
                  lable="First Name"
                  type="Name"
                  value={data.name}
                  name="name"
                  onChange={handelonChange}
                />
               
                <TextFieldView
                  placeholder="Doe"
                  lable="Last Name"
                  type="LastName"
                  value={data.last}
                  name="last"
                  onChange={handelonChange}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", sm: "row" },
                  width: {xs:"100%",sm:"102.5%",md:"100%"}
                }}
              >
                <TextFieldView
                  placeholder="johndoe@gmail.com"
                  lable="Email Address"
                  type="Email"
                  value={data.email}
                  name="email"
                  onChange={handelonChange}
                />
                <TextFieldView
                  placeholder="+1 55992 74815"
                  lable="Phone Number"
                  type="number"
                  value={data.number}
                  name="number"
                  onChange={handelonChange}
                />
              </Box>

              <Box
                sx={{
                  width: {
                    xs: "100%",

                    md: "455.5px",
                    lg: "575.5px",
                  },
                }}
              >
                <Box
                  sx={{
                    boxShadow: "0",
                  }}
                >
                  <Typography
                    variant="body2"
                    color="#000"
                    sx={{
                      opacity: "0.5",
                    }}
                  >
                    Message
                  </Typography>

                  <TextField
                    onChange={handelonChange}
                    value={data.message}
                    name="message"
                    type="Message"
                    color="secondary"
                    placeholder={"Your Message Here"}
                    multiline
                    rows={4}
                    defaultValue="Default Value"
                    InputProps={{
                      style: {
                        borderRadius: "5px",
                        backgroundColor: "#F8F8F8",
                      },
                    }}
                    style={{
                      width: "100%",
                    }}
                  />
                </Box>
                <Box sx={{}}>
                  <Button
                    onClick={handelOnClick}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "455px",
                        lg: "575.5px",
                      },

                      background:
                        "linear-gradient(180deg, #2DB7F6 0%, #038DCB 100%)",
                      px: "1.2rem",
                      py: "0.5rem",
                      mt: "1rem",
                      textTransform: "none",
                      fontSize: "1rem",
                      "&:hover": {
                        background:
                          "linear-gradient(180deg, #2DB7F6 0%, #038DCB 100%)",
                      },
                    }}
                  >
                    Send
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ ml: { xs: 0, md: 2.5 },display:"flex",alignItems:"center",justifyContent:"center" }}>
          <Box
            sx={{
              background: "#F8F8F8",
              mt: { xs: 3, md: 0 },
              borderRadius: "17px",
              width:"100%"
            }}
          >
            <Box
              sx={{
                px: { xs: 2, sm: 4 },
                pt: { xs: 4, md: 4 },
              }}
            >
              <Box>
                <Typography
                  variant="body2"
                  color="#0A0A0A"
                  sx={{
                    opacity: "0.5",
                  }}
                >
                  Or Contact Us at
                </Typography>
              </Box>
              <Box sx={{}}>
                <SupportView
                  media={Msg}
                  value="support@lunchmaybedinner.net"
                />
              </Box>
              <Box>
                <SupportView media={Phone} value="3013277295" />
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <img src={Vector} alt="" />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ContactUs;

function TextFieldView({ lable, type, value, name, onChange, placeholder }) {
  return (
    <Box
      sx={{
        pr: { xs: "0", sm: "1rem" },
        pb: 2,
        width: "100%",
      }}
    >
      <Typography
        variant="body2"
        color="#000"
        sx={{
          opacity: "0.5",
         
        }}
      >
        {lable}
      </Typography>

      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "end",
          pt:0.5
        }}
      >
        <TextField
          placeholder={placeholder}
          value={value}
          name={name}
          onChange={onChange}
          type={type}
          color="secondary"
          InputProps={{
            style: {
              borderRadius: "5px",
              backgroundColor: "#F8F8F8",
            },
          }}
          sx={{
            width: { xs: "100%", md: "220px", lg: "280px" },
            "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
              {
                display: "none",
              },
            input: {
              height: "0.5rem",
              fontSize: "15px",
              borderRadius: "5px",
            },
          }}
        />
      </Box>
    </Box>
  );
}

function SupportView({ media, value }) {
  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        my: 2,
       
     
      }}
    >
      <Box sx={{display: "flex",
        alignItems: "center",}}>
        <img src={media} alt="" />
      </Box>
      
        <Typography variant="body1" color="initial" sx={{ pl: 1,overflow:"hidden",
        textOverflow:"ellipsis",pb:0.5 }}>
          {value}
        </Typography>
      
    </Box>
  );
}
