import { Box, ThemeProvider } from "@mui/material";
import { theme } from "./utils/Theme";
import Circle from "./Component/Image/Circle.png";
import Home from "./Component/Home/Home Screen/Home";
import { useState } from "react";
import Privacy from "./Component/Privacypolicy/Screen/Privacy";
import Terms from "./Component/Privacypolicy/Screen/Terms";
import Conduct from "./Component/Privacypolicy/Screen/Conduct";
import { BrowserRouter as Brows,Routes,Route } from "react-router-dom";
import Navbar from "./Component/Home/Screen/Navbar";
import Footer from "./Component/Home/Screen/Footer";


function App() {
  const [showBtn, setShowBtn] = useState("myBtn none");
  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      setShowBtn("myBtn");
    } else {
      setShowBtn("none");
    }
  }
  function topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <Box>
      <ThemeProvider theme={theme("light")}>
        <Box>
          <Box
            onClick={topFunction}
            className={showBtn}
            id="myBtn"
            title="Go to top"
          >
            <Box title="Go to top">
              <img src={Circle} alt="" width="50px" title="Go to top" />
            </Box>
          </Box>
        </Box>
        <Box>
        <Brows>
          <Navbar/>
          <Routes>
              <Route path="/" element={<Home/>} />
              <Route path="/Privacypolicy" element={<Privacy/>}/>
              <Route path="/TermsandConditions" element={<Terms/>}/>
              <Route path="/CodeofConduct" element={<Conduct/>}/>
          </Routes>
          <Footer/>
        </Brows>
        </Box>
      </ThemeProvider>
    </Box>
  );
}

export default App;
