
import { Box } from "@mui/system";
import React from "react";

import Typography from "@mui/material/Typography";

const TypographyViwe = ({value,media,title,mb,width}) => {
  return (
   <Box sx={{ marginTop:{xs:"6rem",md:"7rem"} ,width:"100%"}}>
    <Box>
        <Box>
          <Box>
            <Typography
              variant="h3"
              color="#0A0A0A"
              fontWeight="600"
              sx={{ textAlign: "center", mb: mb,pb:1 ,px:{xs:3,sm:0}}}
            >
             {value}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box sx={{ width: { xs: "90%", sm: width } }}>
              <img src={media} alt="" width="100%" />
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body2"
              color="#0A0A0A"
              sx={{
                width: { xs: "95%", sm: "90%", md: "48rem", lg: "55rem" },
                textAlign: "center",
              }}
            >
              {title}
            </Typography>
          </Box>
        </Box>
      </Box>
   </Box>
  )
}

export default TypographyViwe